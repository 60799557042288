import { render, staticRenderFns } from "./StationShowDetailsRoute.vue?vue&type=template&id=13760bb2&scoped=true&"
import script from "./StationShowDetailsRoute.vue?vue&type=script&lang=js&"
export * from "./StationShowDetailsRoute.vue?vue&type=script&lang=js&"
import style0 from "./StationShowDetailsRoute.vue?vue&type=style&index=0&id=13760bb2&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../.yarn/$$virtual/vue-loader-virtual-2fffd51942/0/cache/vue-loader-npm-15.9.3-4ca89c7e4b-bc7d4c63e0.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13760bb2",
  null
  
)

export default component.exports
<template>
  <div class="volume-slider">
    <UIIcon class="icon iconvolumedown" :name="iconToDisplay"/>
    <div class="inputwrapper">
      <input
        class="inputrange"
        type="range"
        v-model.number="localValue"
        @input="onInput"
        @change="onChange"
        min="0"
        max="1"
        step="0.01"
      >
      <i class="thumb" :style="thumbStyle"></i>
      <i class="volumebar" :style="barStyle"></i>
      <i class="backgroundbar"></i>
    </div>

    <UIIcon class="icon iconvolumeup" name="volume-up"/>
  </div>
</template>

<script>
import Vue from 'vue';
import UIIcon from '@/core/shared/components/ui/UIIcon';

import Component from 'vue-class-component';

@Component({
  props: {
    value: {
      required: true,
      type: Number,
    },
  },
  components: {
    UIIcon,
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (!this.editing) this.localValue = value || 0;
      },
    },
  },
})
export default class VolumeSlider extends Vue {
  localValue = 0
  editing = false
  get iconToDisplay () {
    return this.value === 0 ? 'volume-muted' : 'volume-down';
  }

  get barStyle () {
    return {
      width: `${Math.round(this.localValue * 100)}%`,
    };
  }

  get thumbStyle () {
    return {
      left: `calc(${Math.round(this.localValue * 100)}%)`,
    };
  }

  onInput () {
    this.editing = true;
    this.$emit('input', this.localValue);
  }

  onChange () {
    this.editing = false;
    this.$emit('change', this.localValue);
  }

  volumeUp () {
    this.$emit('change', Math.min(this.localValue + 0.1, 1));
  }

  volumeDown () {
    this.$emit('change', Math.max(this.localValue - 0.1, 0));
  }
}
</script>

<style lang="postcss" scoped>
@import "core/shared/styles";

.volume-slider {
  display: flex;
  & > * + * {
    margin-left: 1.3rem;
  }
  align-items: center;
  & .icon {
    width: 2rem;
    stroke: var(--colorFjord);
  }
  & .iconvolumeup {
    margin-left: 1.7rem;
  }
  & .inputwrapper {
    position: relative;
    flex: 1 1 auto;
  }
  & .inputrange {
    position: absolute;

    appearance: none;
    left: -1.5rem;
    top: -1.25rem;
    width: calc(100% + 3rem);
    opacity: 0;
    z-index: 10;
    cursor: pointer;

    &::-webkit-slider-thumb {
      height: 3rem;
      width: 3rem;

      border-radius: 100%;
      appearance: none;
      background: var(--colorFjord);
    }
    &::-moz-range-thumb {
      height: 3rem;
      width: 3rem;

      border-radius: 100%;
      appearance: none;
      background: var(--colorFjord);
    }
  }
  & .thumb {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;
    background-color: var(--colorFjord);
    display: block;
    height: 1.8rem;
    width: 1.8rem;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: transform 0.15s ease-in-out;
  }

  & .volumebar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: var(--colorFjord);
    display: block;
    height: 0.5rem;
    border-radius: 2rem;
  }

  &:hover {
    & .backgroundbar {
      background-color: var(--colorGeyser);
    }
    & .thumb {
      transform: translate(-50%, -50%) scale(1.1, 1.1);
    }
  }
  & .backgroundbar {
    background-color: var(--colorCasper);
    display: block;
    height: 0.5rem;
    border-radius: 2rem;
    transition: background-color 0.25s ease-in-out;
  }
}
</style>

<template>
  <div class="station-content-tab">
    <ContentBlock :loading="isFetchingData">
      <template #instructions>
        <h5>{{ $t('Number of Titles on this Station') }}</h5>
        <p>{{ $t('An overview of your available titles.') }}</p>
      </template>

      <div>
        <div class="stats">
          <div class="titlesonstation">
            <div class="label f6">{{ $t('Enabled Titles on this Station') }}</div>
            <div class="value f3">{{ titlesOnThisStation.length }}</div>
          </div>
          <div class="titlestotal">
            <div class="label f6">{{ $t('Number of Titles in My Library') }}</div>
            <div class="value f3">{{ games.length }}</div>
          </div>
        </div>
      </div>
    </ContentBlock>

    <ContentBlock :loading="isFetchingData">
      <template #instructions>
        <h5>{{ $t('Add Content to this Station') }}</h5>
        <p v-if="$permissions['education'] || $permissions['enterprise-operator']">{{ $t('Search for content in your Library to assign to this station.') }}</p>
        <i18n
          v-else
          tag="p"
          path="Search for content in your Library to assign to this station. To add titles to your Library, go to the {marketplaceLink}."
        >
          <template #marketplaceLink>
            <RouterLink :to="{ name: 'marketplace' }" class="link-style">{{ $t('Marketplace') }}</RouterLink>
          </template>
        </i18n>
      </template>
      <div class="stationtitles">
        <template v-if="games.length">
          <div class="searchandfilter">
            <UIInput :placeholder="$t('Search for a Title')" type="search" v-model="search" />
            <UISelect v-model="filter" :title="$t('Show all')">
              <option value="all">{{ $t('Titles in My Library') }}</option>
              <option value="enabled">{{ $t('Titles enabled on this Station') }}</option>
              <option value="disabled">{{ $t('Titles disabled on this Station') }}</option>
            </UISelect>
          </div>

          <div class="searchtable">
            <div class="tableheader f6 storm">
              <div>{{ $t('Enable Station on Title') }}</div>
              <div class="priceheader dn db-m">{{ $t('Licenses') }}</div>
              <div class="tr">{{ $t('Edit Title') }}</div>
            </div>
            <template v-if="gameResults.length > 0 && stationGames">
              <div class="tablesubheader">
                <UISwitch
                  :title="$t('Select All')"
                  :value="isSelectAllOn"
                  @change="toggleAllSelected(gameResults, !isSelectAllOn)"
                />
              </div>
              <div class="gameresult" v-for="game in gameResults" :key="game.id">
                <div class="gameresultrow">
                  <ContentStationManagementSwitches
                    :title="game.title"
                    :stationGame="getStationGame(game)"
                    :gameType="getGameType(game)"
                    :labels="true"
                    @installStationGame="installStationGame"
                    @uninstallStationGame="uninstallStationGame"
                    @requestKeyForStationGame="requestKeyForStationGame"
                    @enableStationGame="enableStationGame"
                    @disableStationGame="disableStationGame"
                  />
                  <div class="f6 pricevalue dn db-m">
                    <span v-if="game.ownership">
                      <UseObject :obj="formatLicenses(game.ownership)" v-slot="{ obj }">
                        {{ $t('Per Minute: {value}', { value: obj.minute.available ? $t('Active') : $t('Not Available') }) }}
                        <template
                          v-if="obj.monthly.count"
                        >| {{ $t('Monthly: {value}', { value: obj.monthly.count }) }}</template>
                        <template
                          v-if="obj.yearly.count"
                        >| {{ $t('Yearly: {value}', { value: obj.yearly.count }) }}</template>
                      </UseObject>
                    </span>
                  </div>
                  <UIButton
                    class="tc"
                    tiny
                    icon="edit"
                    :target="($store.state.isEmbedded) ? '' : '_blank'"
                    :to="{ name: 'content-library-show', params: { gameId: game.id } }"
                  />
                </div>
              </div>
            </template>

            <div class="empty f-body tc center fill-geyser fadeIn animated" v-else>
              <UIIcon name="no-results" class="dib mv5" style="width:8rem;" />
              <p>{{ $t("We couldn't find any titles matching your search.") }}</p>
            </div>
          </div>
        </template>

        <div class="empty f-body tc center fill-geyser fadeIn animated" v-else>
          <UIIcon name="no-results" class="dib mv5" style="width:8rem;" />
          <p v-if="$permissions['education'] || $permissions['enterprise-operator']">{{ $t('Your Library is empty.') }}</p>
          <i18n
            v-else
            tag="p"
            path="Your Library is empty. Go to the {marketplaceLink} to add content to your Library."
          >
            <template #marketplaceLink>
              <RouterLink :to="{ name: 'marketplace' }" class="link-style">{{ $t('Marketplace') }}</RouterLink>
            </template>
          </i18n>
        </div>
      </div>
    </ContentBlock>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import UIInput from '@/core/shared/components/ui/UIInput';
import UISelect from '@/core/shared/components/ui/UISelect';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UISwitch from '@/core/shared/components/ui/UISwitch';
import UIButton from '@/core/shared/components/ui/UIButton';
import ContentBlock from '@/core/shared/components/ContentBlock';
import ContentStationManagementSwitches from '@/shared/content-station-management/ContentStationManagementSwitches';
import { formatLicenses } from '@/core/helpers/FormatHelpers';
import { keyBy } from 'lodash-es';
const UseObject = {
  functional: true,
  props: {
    obj: { required: true },
  },
  render (h, { props, scopedSlots }) {
    return scopedSlots.default({ obj: props.obj });
  },
};
@Component({
  components: {
    UIInput,
    UISelect,
    UIIcon,
    UISwitch,
    UIButton,
    ContentBlock,
    ContentStationManagementSwitches,
    UseObject,
  },
  props: {
    station: {
      type: Object,
      required: true,
    },
    stationId: {
      type: String,
      required: true,
    },
  },
  watch: {
    search: {
      handler: 'onSearchOrFilterChanged',
      immediate: true,
      deep: true,
    },
    filter: {
      handler: 'onSearchOrFilterChanged',
      immediate: true,
      deep: true,
    },
  },
  methods: {
    formatLicenses: ownership => keyBy(formatLicenses(ownership), 'type'),
  },
})
export default class StationShowContentRoute extends Vue {
  search = '';
  filter = 'all';

  noFlash = false;

  onSearchOrFilterChanged () {
    this.$store.dispatch('stationGameSearch', { stationId: this.stationId, search: this.search, filter: this.filter });
  }

  get isFetchingData () {
    return (this.$store.state.stations.isFetchingGames || this.$store.state.stations.isFetchingStationDetail) &&
    (this.games.length === 0 || this.titlesOnThisStation.length === 0);
  }

  get filteredGameIds () {
    return this.$store.state.stations.filteredGameIds;
  }

  get gameResults () {
    let games;
    if (this.filteredGameIds) {
      games = this.games.filter(game => ~this.filteredGameIds.indexOf(game.id));
    } else {
      games = this.games;
    }
    return games;
  }

  get games () {
    return this.$store.getters['entities/Game/all']().sort((a, b) => {
      if (a.title < b.title) return -1;
      if (a.title > b.title) return 1;
      return 0;
    });
  }

  get stationGames () {
    return this.$store.getters[`stationManagement:${this.stationId}/stationGamesMapKeydByGame`];
  }

  get titlesOnThisStation () {
    return this.games.filter(game => {
      if (this.stationGames) return this.stationGames[game.id].enabled;
    });
  }

  get isSelectAllOn () {
    return this.gameResults.every(game => {
      if (this.stationGames) return this.stationGames[game.id].enabled;
    });
  }

  async toggleAllSelected (games, enable) {
    this.noFlash = true;

    const previousStateOfGames = [];
    // here we copy this.stationGames in tmpStationGames in order to prevent the getter to be recomputed at each step of the loop
    const tmpStationGames = this.stationGames;
    for (const game of games) {
      const stationGame = tmpStationGames[game.id];
      previousStateOfGames.push({
        id: game.id,
        stationGame: {
          enabled: stationGame.enabled,
        },
      });
      const action = enable ? 'enableStationGame' : 'disableStationGame';
      this.$store.dispatch(`stationManagement:${this.stationId}/${action}`, stationGame);
    };

    await this.$nextTick();
    this.noFlash = false;

    if (enable) {
      this.multipleEnableNotification(games);
    } else if (!enable && this.shouldShowAskDisableOrUninstallModal(games) && this.hasACDSGame(games)) {
      this.showAskDisableOrUninstallModal(games, this.hasANonCDSGame(games), previousStateOfGames);
    } else if (!enable) {
      this.multipleDisableNotification(games);
    }
  }

  getGameType (game) {
    if (!game) return;
    let type;
    if (game.ownership && game.ownership.cds) type = 'cds';
    else if (game.ownership && !game.ownership.cds) type = 'steam';
    else if (!game.ownership) type = 'manual';
    return type;
  }

  getStationGame (game) {
    return this.stationGames[game.id];
  }

  installStationGame (stationGame) {
    this.$store.dispatch(`stationManagement:${this.stationId}/installStationGame`, stationGame);
  }

  uninstallStationGame (stationGame) {
    this.$store.dispatch(`stationManagement:${this.stationId}/uninstallStationGame`, stationGame);
    this.showLicenseWarningNotification(stationGame);
  }

  requestKeyForStationGame (stationGame) {
    this.$store.dispatch(`stationManagement:${this.stationId}/requestGameKey`, stationGame);
  }

  enableStationGame (stationGame, gameType) {
    this.$store.dispatch(`stationManagement:${this.stationId}/enableStationGame`, stationGame);
    this.singleEnableNotification(gameType);
  }

  disableStationGame (stationGame, gameType) {
    this.$store.dispatch(`stationManagement:${this.stationId}/disableStationGame`, stationGame);
    this.singleDisableNotification(gameType);
    this.showLicenseWarningNotification(stationGame);
  }

  singleEnableNotification (gameType) {
    let message;
    if (gameType === 'steam') {
      message = this.$t('The title will be enabled on your Station after you save this page. Remember to install the title on your computer and request a Steam Key if needed.');
    } else if (gameType === 'manual') {
      message = this.$t('The title will be enabled on your Station after you save this page. Remember to install the title on your computer.');
    }

    if (message && !this.noFlash) {
      this.$store.commit('removeFlash');
      this.$store.commit('setFlash', {
        type: 'info',
        message,
      });
    }
  }

  singleDisableNotification (gameType) {
    let message;
    if (gameType === 'steam') {
      message = this.$t('The title will be disabled from your Station after you save this page. Remember to uninstall the title from your computer.');
    } else if (gameType === 'manual') {
      message = this.$t('The title will be disabled from your Station after you save this page. Remember to uninstall the title from your computer.');
    }

    if (message && !this.noFlash) {
      this.$store.commit('removeFlash');
      this.$store.commit('setFlash', {
        type: 'info',
        message,
      });
    }
  }

  showLicenseWarningNotification (stationGame) {
    const stationGameInStore = this.$store.getters['entities/StationGame'](stationGame.id);
    if (stationGameInStore.game.stationsInstalled <= stationGameInStore.game.license.monthlyLicensesCount + stationGameInStore.game.license.yearlyLicensesCount) {
      const message = this.$t('After you save, you will have more monthly or yearly licenses than enabled stations for {title}.', { title: stationGameInStore.game.title });
      this.$store.commit('setFlash', {
        type: 'error',
        message,
      });
    }
  }

  hasANonCDSGame (games) {
    return !!games.find(game => {
      if (game.ownership) {
        return !game.ownership.cds;
      } else {
        return true;
      }
    });
  }

  hasACDSGame (games) {
    return !!games.find(game => {
      if (game.ownership) {
        return game.ownership.cds;
      } else {
        return false;
      }
    });
  }

  multipleEnableNotification (games) {
    const hasANonCDSGame = this.hasANonCDSGame(games);
    if (hasANonCDSGame) {
      this.$store.commit('removeFlash');
      this.$store.commit('setFlash', {
        type: 'info',
        message: this.$t('The titles will be enabled on your Station after you save this page. Remember to install the titles on your computer if needed.'),
      });
    }
  }

  multipleDisableNotification (games) {
    const hasANonCDSGame = this.hasANonCDSGame(games);
    if (hasANonCDSGame) {
      this.$store.commit('removeFlash');
      this.$store.commit('setFlash', {
        type: 'info',
        message: this.$t('The titles will be disabled on your Station after you save this page. Remember to uninstall the titles from your computer if needed.'),
      });
    }
  }

  shouldShowAskDisableOrUninstallModal (games) {
    return !!games.find(game => {
      const stationGame = this.stationGames[game.id];
      return 'id' in stationGame && stationGame._originalValue === true;
    });
  }

  showAskDisableOrUninstallModal (games, hasANonCDSGame, previousStateOfGames) {
    let messageHTML = `${this.$t('If you would like to keep the content installed on your computer but disabled on your station, select “Disable Only”. To uninstall the content, click on “Disable & Uninstall”.')}`;
    const tmpStationGame = this.stationGames;
    if (hasANonCDSGame) {
      messageHTML += `
        <div class="confirm-warning"><svg><use xlink:href="#icon-alert-triangle"></use></svg> ${this.$t('“Disable & Uninstall” only applies to One-Click titles. For Steam and manually added content, please uninstall manually.')}</div>
      `;
    }
    this.$store.commit('setConfirm', {
      show: true,
      message: messageHTML,
      icon: 'x',
      buttons: [
        {
          name: this.$t('Disable Only'),
          type: 'ghost',
          action: () => {
            games.forEach(game => {
              const stationGame = tmpStationGame[game.id];
              this.disableStationGame(stationGame);
            });
          },
        },
        {
          name: this.$t('Disable & Uninstall'),
          action: () => {
            games.forEach(game => {
              const stationGame = tmpStationGame[game.id];
              this.uninstallStationGame(stationGame);
            });
          },
        },
      ],
      onClose: () => {
        previousStateOfGames.forEach(game => {
          const action = (game.stationGame.enabled) ? 'enableStationGame' : 'disableStationGame';
          this.$store.dispatch(`stationManagement:${this.stationId}/${action}`, tmpStationGame[game.id]);
        });
      },
    });
  }
}
</script>

<style lang="postcss" scoped>
@import "core/shared/styles";

.stats {
  display: grid;
  grid-gap: var(--spacingSm);
  grid-template-columns: 1fr 1fr;
}

.stationtitles {
  display: grid;
  grid-gap: var(--spacingMd);

  & .searchandfilter {
    display: grid;
    grid-gap: var(--spacingMd);

    @media (--tablet) {
      grid-template-columns: 1fr 1fr;
      align-items: end;
    }
  }

  & .searchtable {
    display: grid;
    grid-gap: var(--spacingMd);

    & .priceheader,
    & .pricevalue {
      text-align: center;

      @media (--tablet) {
        text-align: left;
      }
    }

    & .gameresultrow {
      display: grid;
      grid-template-columns: 1fr 5rem;
      align-items: center;

      @media (--tablet) {
        grid-gap: var(--spacingMd);
        grid-template-columns: 1fr 1fr 5rem;
      }
    }

    & .tableheader {
      display: grid;
      grid-template-columns: 1fr 5rem;
      align-items: center;

      @media (--tablet) {
        grid-gap: var(--spacingMd);
        grid-template-columns: 1fr 1fr 5rem;
      }
    }

    & .tableheader {
      padding-bottom: var(--spacingSm);
      border-bottom: 0.1rem solid var(--colorStorm);
    }
  }
}
</style>

<style lang="postcss">
@import "core/shared/styles";

.confirm-warning {
  color: var(--colorErrorFlat);
  margin-top: var(--spacingSm);
  font-style: italic;

  & svg {
    display: inline-block;
    width: 1.3rem;
    height: 1.3rem;
    margin-right: var(--spacingXS);
    fill: var(--colorErrorFlat);
  }
}
</style>

<template>
  <div class="station-show">
    <sub-header v-if="$route.name !== 'license-item'">
      <ul class="tabs" slot="tabs">
        <RouterLink tag="li" :to="{ name: 'station-show-details' }">{{ $t("Station Details") }}</RouterLink>
        <RouterLink tag="li" :to="{ name: 'station-show-content' }" v-if="stationId !== 'new'">{{ $t("Content") }}</RouterLink>
      </ul>
    </sub-header>

    <div class="container" v-if="station">
      <div class="topactions">
        <BreadcrumbNavConnected class="breadcrumb" />
        <UIButton
          tiny
          icon="trash"
          v-if="station.id"
          @click="deleteStation"
          class="delete-station"
        >{{ $t("Delete Station") }}</UIButton>
      </div>
      <RouterView :station="station" :experiences="experiences" @edit="editStation" />
    </div>

    <BottomBar @save="storeStation" :saving="saving" :valid="valid" :backRouterLink="{ name: 'station-index' }"/>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';

import FocusModeMixin from '@/core/shared/misc/FocusModeMixin';

import SubHeader from '@/core/shared/components/SubHeader';
import BottomBar from '@/core/shared/components/BottomBar';
import BreadcrumbNavConnected from '@/core/shared/components/_connected/BreadcrumbNavConnected';
import UIButton from '@/core/shared/components/ui/UIButton';

import { isEqual } from 'lodash-es';

import { fromStation } from '@/shared/content-station-management/module';

@Component({
  components: {
    SubHeader,
    BottomBar,
    BreadcrumbNavConnected,
    UIButton,
  },
  props: {
    stationId: {
      type: String,
      required: true,
    },
    locationId: {
      type: String,
      required: true,
    },
  },
})
export default class StationShowRoute extends mixins(FocusModeMixin) {
  defaultStation = {
    title: 'New Station',
    experience: {
      id: null,
    },
    location: {
      id: null,
    },
    games: [],
    steamPath: null,
    bookingEnabled: true,
    launcherContentPreviewEnabled: true,
    defaultDashboardElementsEnabled: false,
    themeParkModeEnabled: false,
    timerEnabled: true,
    launcherEnabled: true,
    quitGameOnTimeUp: false,
    idleMode: 'videos',
    idleModeVolume: 0.5,
  };

  stationChanges = {};
  saving = false;

  get valid () {
    if (this.station) return !!this.station.title;
  }

  async created () {
    this.$store.registerModule(`stationManagement:${this.stationId}`, fromStation);
    this.$store.commit(`stationManagement:${this.stationId}/setStationId`, this.stationId);

    // Small check for content-only, which has no location but extends this component
    if (this.locationId) {
      this.setBreadcrumb({
        indexTitle: this.$tc('Station', 2),
        editTitle: (this.stationId === 'new') ? this.$t('New Station') : this.$t('Edit Station'),
        indexRouteName: 'station-index',
      });
    }

    if (this.stationId === 'new') {
      this.$store.commit('setTitle', this.$t('New Station'));
      this.stationChanges = this.defaultStation;
      this.stationChanges.location.id = this.locationId;
    } else {
      this.$store.commit('setTitle', this.$t('Edit Station'));
      await this.$store.dispatch('getStationDetail', this.stationId);
      this.setBreadcrumbsForStation();
    }
  }

  destroyed () {
    this.$store.unregisterModule(`stationManagement:${this.stationId}`);
    this.$store.commit('resetSearch');
  }

  setBreadcrumbsForStation () {
    this.setBreadcrumb({
      indexTitle: this.$tc('Station', 2),
      editTitle: (this.stationId === 'new') ? this.$t('New Station') : this.$t('Edit Station'),
      indexRouteName: 'station-index',
    });
  }

  get stationInStore () {
    return (this.stationId === 'new') ? this.defaultStation : this.$store.getters['entities/Station'](this.stationId);
  }

  get station () {
    return { ...this.stationInStore, ...this.stationChanges };
  }

  editStation (property, value) {
    const dirty = !isEqual(this.stationInStore[property], value);
    if (dirty) this.$set(this.stationChanges, property, value);
    else this.$delete(this.stationChanges, property);
  }

  get experiences () {
    const experiences = this.$store.getters['entities/Experience'](experience => {
      if (experience.location) return experience.location.id === this.locationId;
    });
    if (this.stationId === 'new' && experiences.length > 0) {
      this.$set(this.stationChanges, 'experience', { id: experiences[0].id });
    }
    return experiences;
  }

  get stationCount () {
    return this.$store.getters.currentOrganization.currentStationCount;
  }

  deleteStation () {
    let message;
    if (this.stationCount === 10) {
      message = this.$t('Are you sure you would like to delete this station? By reducing your total station count below 10, you will no longer be qualified for a volume discount.');
    } else if (this.stationCount === 1) {
      message = this.$t('You cannot delete your last station. Would you rather disable your account? Note: you will loose access to all features in your account if you decide to disable your account.'); // HARDCODED
    } else {
      message = this.$t('Are you sure you want to delete this station? Starting next month, your station fees will decrease by your current per station rate and we will reimburse the prorated amount for the current month.');
    }
    const buttons = [
      {
        name: this.$t('No'),
        type: 'cancel',
      },
    ];
    if (this.stationCount !== 1) {
      buttons.push({
        name: this.$t('Yes, Delete'),
        action: async () => {
          await this.$store.dispatch('deleteStation', { id: this.station.id });
          this.$router.push({ name: 'station-index' });
        },
      });
    } else {
      buttons.push({
        name: this.$t('Disable account'),
        type: 'secondary',
        action: async () => {
          this.$router.push({ name: 'billing', query: { disable: 1 } });
        },
      });
    }

    this.$store.commit('setConfirm', {
      show: true,
      message,
      icon: 'icon-trash',
      buttons,
    });
  }

  async storeStation () {
    this.saving = true;
    this.$store.commit('setLoading', true);
    this.$store.commit('setLoadingOverlay', true);
    try {
      if (this.station.id) this.stationChanges.id = this.station.id;

      this.$store.commit(`stationManagement:${this.stationId}/setStationId`, this.stationId);
      await this.$store.dispatch(`stationManagement:${this.stationId}/persistState`);
      await this.$store.dispatch('storeStation', this.stationChanges);

      this.$store.commit('setFlash', {
        message: `${this.station.title} ${this.$t('was successfully saved.')}`,
        type: 'success',
      });
      this.$router.push({ name: 'station-index' });
    } catch (e) {
      this.$store.commit('setFlash', {
        type: 'error',
        message: this.$t('There was an error saving this station, please try again.'),
      });
    } finally {
      this.$store.commit('setLoading', false);
      this.$store.commit('setLoadingOverlay', false);
      this.saving = false;
    }
  }
}
</script>

<style lang="postcss" scoped>
@import "core/shared/styles";

.station-show {
  & .topactions {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: var(--spacingMd);

    & > * {
      flex: 1 1 auto;

      &:last-child {
        flex: 0 0 auto;
        text-align: right;
      }
    }
  }

  & .container {
    margin-top: var(--spacingMd);
  }
}
</style>
